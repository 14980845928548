import { InputObj } from '@svan-garaaz/reactsharedlib';
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
  Box,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Icon
} from '@chakra-ui/react';
import { FiAlertCircle } from 'react-icons/fi';

interface EnhancedInputProps extends InputObj {
  leftIcon?: React.ElementType;
  rightIcon?: React.ElementType;
  containerProps?: Record<string, any>;
}

const generateInputs = (inputObj: EnhancedInputProps) => {
  const {
    name,
    label,
    type = 'text',
    register,
    size = 'md',
    placeholder,
    onChange,
    defaultValue,
    error,
    helperMessage,
    isInvalid,
    leftIcon,
    rightIcon,
    containerProps = {}
  } = inputObj;

  return (
    <Box mb={4} {...containerProps} key={name}>
      <FormControl isInvalid={isInvalid}>
        <FormLabel
          htmlFor={name}
          mb={1}
          fontSize="sm"
          fontWeight="medium"
          color="gray.700"
          _dark={{ color: 'gray.300' }}
        >
          {label}
        </FormLabel>

        <InputGroup>
          {leftIcon && (
            <InputLeftElement pointerEvents="none">
              <Icon as={leftIcon} color="gray.400" />
            </InputLeftElement>
          )}

          <Input
            id={name}
            type={type}
            {...register}
            size={size}
            placeholder={placeholder}
            onChange={onChange}
            defaultValue={defaultValue}
            borderRadius="md"
            borderColor={isInvalid ? 'red.300' : 'gray.300'}
            _hover={{
              borderColor: isInvalid ? 'red.400' : 'gray.400'
            }}
            _focus={{
              borderColor: isInvalid ? 'red.500' : 'blue.500',
              boxShadow: isInvalid 
                ? '0 0 0 1px var(--chakra-colors-red-500)'
                : '0 0 0 1px var(--chakra-colors-blue-500)',
              zIndex: 1
            }}
            _dark={{
              borderColor: isInvalid ? 'red.500' : 'gray.600',
              _hover: {
                borderColor: isInvalid ? 'red.400' : 'gray.500'
              },
              _focus: {
                borderColor: isInvalid ? 'red.400' : 'blue.300',
                boxShadow: isInvalid 
                  ? '0 0 0 1px var(--chakra-colors-red-400)'
                  : '0 0 0 1px var(--chakra-colors-blue-300)'
              }
            }}
            pl={leftIcon ? 10 : 4}
            pr={rightIcon ? 10 : 4}
            py={2}
          />

          {(rightIcon || isInvalid) && (
            <InputRightElement>
              {isInvalid ? (
                <Icon as={FiAlertCircle} color="red.500" />
              ) : (
                <Icon as={rightIcon} color="gray.400" />
              )}
            </InputRightElement>
          )}
        </InputGroup>

        {error?.message && (
          <FormErrorMessage
            mt={1}
            fontSize="xs"
            color="red.500"
            _dark={{ color: 'red.300' }}
          >
            {error.message}
          </FormErrorMessage>
        )}

        {helperMessage && !error?.message && (
          <FormHelperText
            mt={1}
            fontSize="xs"
            color="gray.500"
            _dark={{ color: 'gray.400' }}
          >
            {helperMessage}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default generateInputs;
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Services from "./services";
import useNotification from "../../utils/useNotification";
import { AxiosError } from "axios";
import globalConstant from "../../utils/constants";
import { queryEnum, messages } from "./constants";
import services from "./services";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorObj } from "@svan-garaaz/reactsharedlib";

export const filterSupplierByBranches = (branches: any[], branchId: string) =>
  branches?.find((branch) => branch?._id === branchId);

export const useGetCustomerDetails = (mobileNumber: any) => {
  const toast = useNotification();
  return useQuery(
    [queryEnum.GET_CUSTOMER_BY_MOBILE_NUMBER, mobileNumber],
    () => Services.getCustomerDetails(mobileNumber),
    {
      onError: (error: AxiosError) => {
        const errorObj: ErrorObj = error.response!.data as ErrorObj;

        toast({ description: errorObj.errorMsg, status: globalConstant.Error });
      },
    }
  );
};

export const useGetSupplierInformation = (
  supplierId: any,
  supplierBranchId: any
) => {
  const toast = useNotification();
  return useQuery(
    [queryEnum.GET_SUPPLIER_INFO, supplierId],
    () => Services.getSupplierDetails(supplierId),
    {
      staleTime: Infinity,
      select: (data) => {
        let filterData;
        filterData = filterSupplierByBranches(data.branches, supplierBranchId);
        return {
          supplierName: data?.businessName,
          branchName: filterData?.branchName,
        };
      },
      onError: (error: AxiosError) => {
        const errorObj: ErrorObj = error.response!.data as ErrorObj;

        toast({ description: errorObj.errorMsg, status: globalConstant.Error });
      },
    }
  );
};

export const useCreateCustomerByRole = () => {
  const toast = useNotification();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return useMutation(Services.createCustomerByRole, {
    onError: (error: AxiosError) => {
      const errorObj: ErrorObj = error.response!.data as ErrorObj;

      toast({ description: errorObj.errorMsg, status: globalConstant.Error });
    },

    onSuccess: (data) => {
      navigate(
        `${pathname.slice(0, pathname.length - 10)}${
          data.mobileNumber
        }/overview`
      );
      toast({
        description: messages.CREATE_CUSTOMER_SUCCESS_MESSAGE,
        status: globalConstant.Success,
      });
    },
  });
};

export const useUpdateCustomerDetails = () => {
  const toast = useNotification();
  const queryClient = useQueryClient();

  return useMutation(Services.editCustomerDetails, {
    onError: (error: AxiosError) => {
      const errorObj: ErrorObj = error.response!.data as ErrorObj;

      toast({ description: errorObj.errorMsg, status: globalConstant.Error });
    },

    onSuccess: (data) => {
      queryClient.invalidateQueries(
        [queryEnum.GET_CUSTOMER_BY_MOBILE_NUMBER],
        data.mobileNumber
      );
      toast({
        description: messages.UPDATED_CUSTOMER_SUCCESS_MESSAGE,
        status: globalConstant.Success,
      });
    },
  });
};

export const useGetCitiesAndStates = () => {
  const toast = useNotification();
  return useQuery(
    [queryEnum.GET_CITIES_AND_STATES],
    Services.getCitiesAndStates,
    {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select(data) {
        const states = Object.keys(data);
        const statesWithCities = Object.fromEntries(
          Object.entries(data).map(([key, value]) => {
            return [key.toUpperCase(), value];
          })
        );
        return {
          states,
          cities: statesWithCities,
        };
      },
      onError: (error: AxiosError) => {
        const errorObj: ErrorObj = error.response!.data as ErrorObj;

        toast({ description: errorObj.errorMsg, status: globalConstant.Error });
      },
    }
  );
};

export const useGetMobileConfig = () => {
  return useQuery(
    [queryEnum.GET_MOBILE_CONFIG_KEY],
    async () => {
      const response = await Services.getMobileConfigs();
      return {
        customer_types: JSON.parse(response?.customer_types ?? "[]"),
        supplier_types: JSON.parse(response?.supplier_types ?? "[]"),
        supplier_rating_types: JSON.parse(response?.supplier_ratings ?? "[]"),
        default_customer_type: response?.default_customer_type ?? "Workshop",
      };
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useGetAllSuppliers = () => {
  return useQuery([queryEnum.GET_ALL_SUPPLIERS], services.getAllSuppliers, {
    staleTime: Infinity,
  });
};

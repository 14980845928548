/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import PageScaffold from "../../components/PageScaffold";
import { useParams } from "react-router-dom";
import { Flex, Spinner } from "@chakra-ui/react";
import { convertToQueryString } from "../../utils/convertQuery";
import { COLUMN_TYPES, GaraazTable } from "@svan-garaaz/reactsharedlib";
import globalConstant from "../../utils/constants";

function index() {
  const [pageTitle, setPageTitle] = useState("Order");
  const [pageInfoText, setPageInfoText] = useState("Order");
  const [query, setQuery] = useState("");

  const authorizationAPItoken =
    localStorage.getItem(globalConstant.MC_SUPER_ADMIN) || "";
  const row = [10, 20, 30, 50, 100, 150];
  const value = 50;

  const TableHeading = [
    {
      header: "Order No",
      accessor: "orderNo",
      type: COLUMN_TYPES.FULLNAME,
      copyable: true,
    },
    {
      header: "Order Date",
      accessor: "orderDate",
      type: COLUMN_TYPES.DATE,
    },
    {
      header: "Order Handler",
      accessor: "orderHandler[0]",
      type: COLUMN_TYPES.FIRST_NAME_LAST_NAME_COMBINE,
    },
    {
      header: "Customer Name",
      accessor: "customer.name",
      type: COLUMN_TYPES.NAME,
    },
  ];

  const { type } = useParams<{ type: string }>();

  let params = {
    isPopulated: true,
  };

  useEffect(() => {
    if (type && !query) {
      let updatedParams: any = { ...params };
      let newTitle = "";
      let newInfoText = "";

      switch (type) {
        case "regular":
          updatedParams.orderType = ["REGULAR"];
          newTitle = "Regular Orders";
          break;

        case "return":
          updatedParams.orderType = ["RETURN"];
          newTitle = "Return Orders";
          break;

        case "trading":
          updatedParams.transactionType = ["Trading"];
          newTitle = "Trading Orders";
          break;

        case "facilitation":
          updatedParams.transactionType = ["Facilitation"];
          newTitle = "Facilitation Orders";
          break;

        case "unhandled":
          updatedParams.orderStatus = ["NEW", "ACK", "ALLOCATED"];
          newTitle = "Unhandled Orders";
          newInfoText = "Orders currently undergoing processing before completion.";
          break;

        case "unattended":
          updatedParams.orderStatus = ["NEW", "ACK", "ALLOCATED"];
          updatedParams.orderType = ["REGULAR"];
          newTitle = "Unattended Orders";
          newInfoText =
            "Regular orders currently undergoing processing before completion.";
          break;

        case "undelivered":
          updatedParams.orderStatus = ["READY_TO_DISPATCH"];
          newTitle = "Undelivered Orders";
          break;
        case "delivered":
          updatedParams.orderStatus = ["DELIVERED"];
          newTitle = "Delivered Orders";
          break;

        case "cbo":
          updatedParams.orderStatus = ["CBO"];
          newTitle = "CBO Orders";
          break;

        case "customer-hold":
          updatedParams.orderStatus = ["CUSTOMER_HOLD"];
          newTitle = "Customer Hold Orders";
          break;

        case "cancelled":
          updatedParams.orderStatus = ["CANCELLED"];
          newTitle = "Cancelled Orders";
          break;

        default:
          newTitle = "Orders";
      }

      setPageTitle(newTitle);
      setPageInfoText(newInfoText);
      setQuery(convertToQueryString(updatedParams));
    }
  }, [type, query]);

  return (
    <PageScaffold
      title={pageTitle}
      infoText={pageInfoText}
    >
      {!query ? (
        <Flex justifyContent="center" alignItems="center" minH="100vh">
          <Spinner size="xl" color="blue.500" />
        </Flex>
      ) : (
        <GaraazTable
          columns={TableHeading}
          url={`${process.env.REACT_APP_BASE_URL}/v2/orders?${query}`}
          limits={row}
          defaultRowLimit={value}
          dataAccessor="orders"
          authorizationAPItoken={authorizationAPItoken}
        />
      )}
    </PageScaffold>
  );
}

export default index;

import { useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Button,
  HStack,
  Alert,
  AlertIcon,
  useDisclosure,
} from '@chakra-ui/react';

import _ from 'lodash';

import { pageTitles } from '../../utils/paths';
import { useGetMobileConfig } from './queryHooks';

import DynamicTable from '../../components/DynamicTable';
import PageScaffold from '../../components/PageScaffold';
import AddConfigModal from './Components/AddConfigModal';
import DeleteConfigModal from './Components/DeleteMobileConfig';
import Enums from './enums';
import Loading from '../../components/Loading';
import { TData } from '@svan-garaaz/reactsharedlib';

const columnHelper = createColumnHelper<TData>();

const Index = () => {
  const { onClose, isOpen, onOpen } = useDisclosure();
  const {
    onClose: onDeleteClose,
    isOpen: isDeleteOpen,
    onOpen: onOpenDelete,
  } = useDisclosure();
  const [rowSelection, setRowSelection] = useState({});
  const { data, isLoading: gettingMobileConfig } = useGetMobileConfig();

  const columns = [
    columnHelper.accessor(Enums.KEY.toLowerCase(), {
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor(Enums.VALUE.toLowerCase(), {
      cell: (info) => {
        const val = info.getValue();
        return typeof val === 'boolean' ? val.toString() : val;
      },
    }),
    columnHelper.accessor(Enums.DESCRIPTION.toLowerCase(), {
      cell: (info) => info.getValue(),
    }),
  ];


  const handleClearSelectedRows = () => {
    setRowSelection({});
  };

  return (
    <PageScaffold title={pageTitles.mobileConfigurations}>
      <Alert status='info'>
        <AlertIcon />
        Provide Configurations for Mobile App
      </Alert>
      {gettingMobileConfig ? (
        <Loading />
      ) : (
        <>
          <HStack my={4} justify='right'>
            <Button
              onClick={onOpenDelete}
              variant='delete'
              isDisabled={_.isEmpty(rowSelection)}
            >
              Delete
            </Button>
            <Button onClick={onOpen} backgroundColor='blue.500'>Add Configuration</Button>
          </HStack>
          <DynamicTable
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            columns={columns}
            data={data ?? []}
          />{' '}
        </>
      )}
      <AddConfigModal onClose={onClose} isOpen={isOpen} />
      <DeleteConfigModal
        clearSelectedRows={handleClearSelectedRows}
        selectedRows={rowSelection}
        onClose={onDeleteClose}
        isOpen={isDeleteOpen}
      />
    </PageScaffold>
  );
};

export default Index;

import {
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Box,
  FormLabel,
  Text,
  Button,
  useDisclosure,
} from "@chakra-ui/react";

import axios from "axios";
import React, { useState, useEffect } from "react";

import GaraazFormLabel from "../../../components/GarrazFormLabel";
import globalConstant from "../../../utils/constants";

import { supplierFormData } from "./AddSupplier";
import useNotification from "../../../utils/useNotification";
import GaraazAlertDialog from "../../../components/GaraazAlertDialog";
import services from "../services";
import { primaryColors, secondaryColors } from "../../../utils/designSystem";

type BranchesDetailsFormSupplierProps = {
  formData: supplierFormData;
  setFormData: React.Dispatch<React.SetStateAction<supplierFormData>>;
  branchDetailsTouchedFields: {
    [key: string]: any;
  };
  setBranchDetailsTouchedFields: React.Dispatch<
    React.SetStateAction<{
      [key: string]: any;
    }>
  >;
  index: number;
};

const BranchesDetailsFormSupplier: React.FC<
  BranchesDetailsFormSupplierProps
> = ({
  formData,
  setFormData,
  branchDetailsTouchedFields,
  setBranchDetailsTouchedFields,
  index,
}) => {
  const [statesCitiesData, setStatesCitiesData] = useState<any>([]);
  const {
    isOpen: isOpenDeleteBranchAlert,
    onOpen: onOpenDeleteBranchAlert,
    onClose: onCloseDeleteBranchAlert,
  } = useDisclosure();

  const toast = useNotification();
  useEffect(() => {
    async function fetchStatesAndCities() {
      const token = localStorage.getItem("MC_SUPER_ADMIN");

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/utility/state-city`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        setStatesCitiesData(response.data);
      } catch (error) {
        console.error("Error fetching states and cities:", error);
      }
    }

    fetchStatesAndCities();
  }, []);

  const handleFieldBlur = (field: string, index: number) => {
    setBranchDetailsTouchedFields((prevbranchDetailsTouchedFields) => ({
      ...prevbranchDetailsTouchedFields,
      [`${field}-${index}`]: true,
    }));
  };
  const isFieldTouched = (field: string, index: number) => {
    return branchDetailsTouchedFields[`${field}-${index}`];
  };

  const handleBranchesDetailsChange = (
    second:
      | "branchName"
      | "branchCode"
      | "branchContactFirstName"
      | "branchContactLastName"
      | "primaryNumber"
      | "secondaryNumber"
      | "addressLine1"
      | "addressLine2"
      | "state"
      | "pinCode"
      | "city"
      | "country",
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    setFormData((previousData) => {
      const newBranchesDetails = previousData.branches;
      newBranchesDetails[index][second] = e.target.value;
      return {
        ...previousData,
        branches: newBranchesDetails,
      };
    });
  };
  const validateMobileNumber = (phoneNumber: string) => {
    const mobileNumberRegex = /^\d{10}$/;
    return mobileNumberRegex.test(phoneNumber);
  };
  return (
    <Box
      borderRadius={"lg"}
      display={"flex"}
      mt="5"
      _first={{ marginTop: "0px" }}
    >
      <Text mr="3">{index + 1}.</Text>
      <Box
        borderRadius={"lg"}
        flex={1}
        display={"flex"}
        flexDirection={"column"}
        key={index}
        border={"1px solid lightgrey"}
        mt="5"
        padding={"10px"}
        backgroundColor={`${secondaryColors.secondary_100}`}
        _first={{ marginTop: "0px" }}
      >
        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id={`branchName${index}`} flex={1}>
            <GaraazFormLabel text="BRANCH NAME" isRequired={true} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Input
                value={formData.branches[index].branchName}
                type="text"
                placeholder="Branch Name"
                width={"100%"}
                name={`branchName${index}`}
                onBlur={() => handleFieldBlur("branchName", index)}
                onChange={(e) => {
                  handleBranchesDetailsChange("branchName", e, index);
                }}
              />
              {isFieldTouched("branchName", index) &&
                formData.branches[index].branchName === "" && (
                  <Text color="red" fontSize={"sm"}>
                    Enter a branch name
                  </Text>
                )}
            </FormControl>
          </Flex>
        </Flex>
        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id={`branchCode${index}`} flex={1}>
            <GaraazFormLabel text="BRANCH CODE" isRequired={false} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Input
                value={formData.branches[index].branchCode}
                type="text"
                placeholder="Branch Code"
                width={"100%"}
                name={`branchCode${index}`}
                onChange={(e) => {
                  handleBranchesDetailsChange("branchCode", e, index);
                }}
              />
            </FormControl>
          </Flex>
        </Flex>
        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl
            width={"25%"}
            id={`branchContactPerson${index}`}
            flex={1}
          >
            <GaraazFormLabel text="BRANCH CONTACT PERSON" isRequired={false} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl flex={1}>
              <Input
                type="text"
                placeholder="Enter branch contact first name"
                name={`branchContactFirstName${index}`}
                value={formData.branches[index].branchContactFirstName}
                onChange={(e) =>
                  handleBranchesDetailsChange(
                    "branchContactFirstName",
                    e,
                    index
                  )
                }
              />
            </FormControl>
            <FormControl flex={1} ml={5}>
              <Input
                type="text"
                placeholder="Enter branch contact last name"
                name={`branchContactLastName${index}`}
                value={formData.branches[index].branchContactLastName}
                onChange={(e) =>
                  handleBranchesDetailsChange("branchContactLastName", e, index)
                }
              />
            </FormControl>
          </Flex>
        </Flex>
        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id={`contactNumber${index}`} flex={1}>
            <GaraazFormLabel text="Primary Number" isRequired={false} />
          </FormControl>
          <FormControl ml={4} width={"75%"}>
            <InputGroup>
              <InputLeftAddon
                children="+91"
                borderColor="lightgray"
                bg="lightgray"
                color="black"
              />
              <Input
                value={formData.branches[index].primaryNumber}
                type="number"
                placeholder="Enter primary number"
                width={"100%"}
                name={`mobileNumber${index}`}
                maxLength={10}
                minLength={10}
                onBlur={() =>
                  handleFieldBlur("branchPrimaryMobileNumber", index)
                }
                onChange={(e) => {
                  handleBranchesDetailsChange("primaryNumber", e, index);
                }}
              />
            </InputGroup>
            {isFieldTouched("branchPrimaryMobileNumber", index) &&
              formData.branches[index].primaryNumber !== "" &&
              (!validateMobileNumber(formData.branches[index].primaryNumber) ||
                formData.branches[index].primaryNumber.length !== 10) && (
                <Text color="red" fontSize={"sm"}>
                  Enter a valid contact number
                </Text>
              )}
          </FormControl>
        </Flex>
        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl
            width={"25%"}
            id={`contactNumberSecondary${index}`}
            flex={1}
          >
            <GaraazFormLabel text="Secondary Number" isRequired={false} />
          </FormControl>
          <FormControl ml={4} width={"75%"}>
            <InputGroup>
              <InputLeftAddon
                children="+91"
                borderColor="lightgray"
                bg="lightgray"
                color="black"
              />
              <Input
                value={formData.branches[index].secondaryNumber}
                type="number"
                placeholder="Enter secondary contact number"
                width={"100%"}
                maxLength={10}
                minLength={10}
                name={`secondaryContactNumber${index}`}
                onBlur={() =>
                  handleFieldBlur("branchSecondaryMobileNumber", index)
                }
                onChange={(e) => {
                  handleBranchesDetailsChange("secondaryNumber", e, index);
                }}
              />
            </InputGroup>
            {isFieldTouched("branchSecondaryMobileNumber", index) &&
              formData.branches[index].secondaryNumber !== "" &&
              (!validateMobileNumber(
                formData.branches[index].secondaryNumber
              ) ||
                formData.branches[index].secondaryNumber.length !== 10 ||
                formData.branches[index].primaryNumber ===
                  formData.branches[index].secondaryNumber) && (
                <Text color="red" fontSize={"sm"}>
                  {`Enter a valid contact number${
                    formData.branches[index].primaryNumber ===
                    formData.branches[index].secondaryNumber
                      ? `, Secondary contact number can not be same.`
                      : ``
                  }`}
                </Text>
              )}
          </FormControl>
        </Flex>
        <Flex align="start" mb={4} width="100%">
          <FormControl flex="1" mr={4}>
            <FormLabel
              style={{
                fontSize: "12px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              htmlFor={"Address Line 1"}
              color="#64748b"
            >
              ADDRESS LINE 1
            </FormLabel>
            <Input
              value={formData.branches[index].addressLine1}
              type="text"
              id={"Address Line 1"}
              placeholder={`Enter Address Line 1`}
              onChange={(e) => {
                handleBranchesDetailsChange("addressLine1", e, index);
              }}
            />
          </FormControl>
          <FormControl flex="1">
            <FormLabel
              style={{
                fontSize: "12px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              htmlFor={"Address Line 2"}
              color="#64748b"
            >
              ADDRESS LINE 2
            </FormLabel>
            <Input
              value={formData.branches[index].addressLine2}
              type="text"
              id={"Address Line 2"}
              placeholder={`Enter Address Line 2`}
              onChange={(e) => {
                handleBranchesDetailsChange("addressLine2", e, index);
              }}
            />
          </FormControl>
        </Flex>

        <Flex align="start" mb={4} width="100%">
          <FormControl flex="1" mr={4}>
            <FormLabel
              style={{
                fontSize: "12px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              htmlFor={"State"}
              color="#64748b"
            >
              STATE
            </FormLabel>
            <Select
              bg="white"
              onChange={(e) => {
                handleBranchesDetailsChange("state", e, index);
              }}
              value={formData.branches[index].state}
              placeholder="Please Select a State"
            >
              {Object.entries(statesCitiesData).map(([key, value], index) => {
                return (
                  <option key={index} value={key}>
                    {key}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl flex="1">
            <FormLabel
              style={{
                fontSize: "12px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              htmlFor={"Pin Code"}
              color="#64748b"
            >
              PIN CODE
            </FormLabel>
            <Input
              value={formData.branches[index].pinCode}
              type="text"
              id={"Pin Code"}
              placeholder={`Enter Pin Code`}
              onChange={(e) => {
                handleBranchesDetailsChange("pinCode", e, index);
              }}
            />
          </FormControl>
        </Flex>

        <Flex align="start" mb={4} width="100%">
          <FormControl flex="1" mr={4}>
            <FormLabel
              style={{
                fontSize: "12px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              htmlFor={"City"}
              color="#64748b"
            >
              CITY
            </FormLabel>
            <Select
              bg="white"
              placeholder="Please Select City"
              onChange={(e) => {
                handleBranchesDetailsChange("city", e, index);
              }}
              value={formData.branches[index].city}
            >
              {statesCitiesData[formData.branches[index].state]?.map(
                (cityOption: any, index: number) => (
                  <option key={index}>{cityOption}</option>
                )
              )}
            </Select>
          </FormControl>
          <FormControl flex="1">
            <FormLabel
              style={{
                fontSize: "12px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              htmlFor={"Country"}
              color="#64748b"
            >
              COUNTRY
            </FormLabel>
            <Input
              isDisabled={true}
              bg="white"
              isReadOnly={true}
              value={"INDIA"}
              type="text"
              id={"Country"}
              placeholder={`Enter Country`}
            />
          </FormControl>
        </Flex>

        <Button
          size="sm"
          color={"red.400"}
          bg="none"
          aria-label="Remove branch"
          ml={"auto"}
          onClick={
            formData.supplierId
              ? () => {
                  onOpenDeleteBranchAlert();
                }
              : () => {
                  setFormData((previousData) => {
                    let newBranchesData = previousData.branches;
                    newBranchesData.splice(index, 1);

                    return {
                      ...previousData,
                      branches: newBranchesData,
                    };
                  });
                  setBranchDetailsTouchedFields({});
                }
          }
          _hover={{ backgroundColor: "#e9ecef !important" }}
          _focus={{ bg: "none", boxShadow: "none" }}
        >
          Delete
        </Button>
      </Box>

      <GaraazAlertDialog
        isOpen={isOpenDeleteBranchAlert}
        onClose={onCloseDeleteBranchAlert}
        submitBtnText="Delete"
        headerText={"Are you sure ?"}
        bodyText={`You are deleting Branch`}
        branchName={formData.branches[index]?.branchName || ""}
        onSubmitButtonClick={async () => {
          try {
            if (formData.branches[index]._id) {
              await services.deleteSupplierBranchById(
                formData.supplierId,
                formData.branches[index]._id
              );
            }
            setFormData((previousData) => {
              let newBranchesData = previousData.branches;
              newBranchesData.splice(index, 1);

              return {
                ...previousData,
                branches: newBranchesData,
              };
            });

            onCloseDeleteBranchAlert();
            toast({
              description: "Successfully Deleted Branch",
              status: globalConstant.Success,
            });
            setBranchDetailsTouchedFields({});
          } catch (e: any) {
            onCloseDeleteBranchAlert();
            toast({
              description: `${e.message}`,
              status: globalConstant.Error,
            });
          }
        }}
      ></GaraazAlertDialog>
    </Box>
  );
};

export default BranchesDetailsFormSupplier;

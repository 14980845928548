import { useMemo, ReactNode, HTMLProps, useRef, useEffect } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
} from '@tanstack/react-table';
import {
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Center,
  Text,
  Tooltip,
  Box,
} from '@chakra-ui/react';
import { TData } from '@svan-garaaz/reactsharedlib';

interface Props {
  columns: ColumnDef<TData, object | ReactNode>[];
  data: TData[];
  setRowSelection?: React.Dispatch<React.SetStateAction<{}>>;
  rowSelection?: { [key: string]: any };
  hideMaker?: boolean;
  noDataText?: string;
}

const parseCellValue = (input: unknown): any => {
  if (input === null || input === undefined) return input;

  // If already an object or array, return as-is
  if (typeof input === 'object') return input;

  if (typeof input === 'string') {
    try {
      const parsed = JSON.parse(input);
      return parsed;
    } catch (e) {
      // Not a JSON string, return the raw string
      return input;
    }
  }

  // Return any other primitive as-is (numbers, booleans, etc.)
  return input;
};


const Index = ({
  columns,
  data,
  setRowSelection,
  rowSelection,
  hideMaker = false,
  noDataText,
}: Props) => {
  // Conditionally add checkbox column
  const checkboxColumn = useMemo<ColumnDef<TData>[]>(
    () =>
      hideMaker
        ? []
        : [
          {
            id: 'select',
            header: ({ table }) => (
              <IndeterminateCheckbox
                checked={table.getIsAllRowsSelected()}
                indeterminate={table.getIsSomeRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
                onClick={(e) => e.stopPropagation()}
              />
            ),
            cell: ({ row }) => (
              <IndeterminateCheckbox
                checked={row.getIsSelected()}
                indeterminate={row.getIsSomeSelected()}
                onChange={row.getToggleSelectedHandler()}
                onClick={(e) => e.stopPropagation()}
              />
            ),
          },
        ],
    [hideMaker]
  );

  const table = useReactTable<TData>({
    data,
    columns: [...checkboxColumn, ...columns],
    state: rowSelection && setRowSelection ? { rowSelection } : {},
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
  });

  // to format (simplify) the cell value on hover
  const formatCellValue = (value: any): string => {

    if (value === null || value === undefined) return '—';

    const format = (val: any): string => {
      if (val === null || val === undefined) return '—';

      if (Array.isArray(val)) {
        if (val.length === 0) return '—';
        return `[ ${val.map(format).join(', ')} ]`;
      }

      if (typeof val === 'object') {
        if (Object.keys(val).length === 0) return '—';
        return `{ ${Object.entries(val)
          .map(([key, v]) => `${key}: ${format(v)}`)
          .join(', ')} }`;
      }

      return val.toString();
    };

    return format(value);
  };



  const tableHeadRows = table.getHeaderGroups().map((headerGroup) => (
    <Tr key={headerGroup.id}>
      {headerGroup.headers.map((header) => (
        <Th
          key={header.id}
          py={{ base: 2, md: 4 }}
          px={{ base: 3, md: 6 }}
          fontSize={{ base: 'xs', md: 'sm' }}
          fontWeight='semibold'
          textTransform='uppercase'
          borderBottom='2px solid'
          borderColor='gray.400'
          textAlign='left'
          style={{
            wordBreak: 'keep-all',
            position: 'sticky',
            top: 0,
            zIndex: 10,
            backgroundColor: '#1e293b',
          }}
          color='white'
        >
          {header.isPlaceholder
            ? null
            : flexRender(header.column.columnDef.header, header.getContext())}
        </Th>
      ))}
    </Tr>
  ));

  const tableBodyRows = table.getRowModel().rows.map((row, rowIndex) => {
    const isSelectable = !hideMaker && !!setRowSelection;

    return (
      <Tr
        key={row.id}
        cursor={isSelectable ? 'pointer' : 'default'}
        onClick={() => isSelectable && row.toggleSelected()}
        _hover={{
          backgroundColor: 'gray.300',
        }}
        transition="all 0.3s ease-in-out"
        bg={rowIndex % 2 === 0 ? 'white' : 'gray.50'}
      >
        {row.getVisibleCells().map((cell) => {
          const value = parseCellValue(cell.getValue());
          const isComplex = typeof value === 'object' || Array.isArray(value);

          const rendered = flexRender(cell.column.columnDef.cell, cell.getContext());

          return (
            <Td
              key={cell.id}
              py={{ base: 2, md: 3 }}
              px={{ base: 3, md: 6 }}
              fontSize={{ base: 'xs', md: 'sm' }}
              borderBottom='1px solid'
              borderColor='gray.200'
              whiteSpace='pre-wrap'
              maxWidth='300px'
              style={{
                wordBreak: 'break-word',
                verticalAlign: 'top',
              }}
            >
              <Tooltip
                label={formatCellValue(value)}
                hasArrow
                isDisabled={!isComplex}
                openDelay={400}
                placement='auto'
                maxWidth="200px"
                whiteSpace="normal"
                wordBreak="break-word"
              >
                <Box>
                  { rendered !== undefined
                      ? rendered
                      : String(value)
                  }
                </Box>
              </Tooltip>
            </Td>
          );
        })}

      </Tr>
    );
  });

  return (
    <TableContainer
      boxShadow='sm'
      border='1px solid'
      borderColor='gray.200'
      borderRadius='lg'
      overflowX='auto'
      maxW='100%'
    >
      {data.length ? (
        <Table variant='simple'>
          <Thead>{tableHeadRows}</Thead>
          <Tbody>{tableBodyRows}</Tbody>
        </Table>
      ) : (
        <Center py={8}>
          <Text color='gray.500' fontSize='md'>
            {noDataText ? 'No New User Intent to be Verified' : 'No Data'}
          </Text>
        </Center>
      )}
    </TableContainer>
  );
};

function IndeterminateCheckbox({
  indeterminate,
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [indeterminate, rest.checked]);

  return <input type="checkbox" ref={ref} {...rest} />;
}

export default Index;

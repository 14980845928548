enum Enums {
    REQUIRED_MESSAGE='Please enter a valid',
    KEY_MATCH_MESSAGE='Key can only contain alphabets, _ and -',
    VALUE_MATCH_MESSAGE='Only letters and numbers are allowed',
    KEY='Key',
    VALUE='Value',
    DESCRIPTION='Description',
    ADD_CONFIG_TITLE='Add Mobile Configuration',
    DELETE_CONFIG_TITLE='Delete Mobile Configuration',
    TRUE='true',
    FALSE='false',
    GET_MOBILE_CONFIG_KEY='mobile_config'
}

export default Enums;
import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

import PageHeader from "./PageHeader";

type Props = {
  children: ReactNode;
  title: string;
  infoText?: string;
  selectMonth?: (month: string) => void;
  selectYear?: (year: string) => void;
  popupModalTitle?: string;
  popupModalText?: string;
  icon?: boolean;
  hasButton?: boolean;
  buttonText?: any;
};
export const PageScaffold = (props: Props) => {
  return (
    <>
      <PageHeader
        title={props.title}
        infoText={props.infoText}
        selectMonth={props.selectMonth}
        selectYear={props.selectYear}
        popupModalTitle={props.popupModalTitle}
        popupModalText={props.popupModalText}
        icon={props.icon}
        hasButton={props.hasButton}
        buttonText={props.buttonText}
      />

      <Box minW="100%" p={4} mt={"10vh"}>
        {props.children}
      </Box>
    </>
  );
};

export default PageScaffold;

const modifyMobileConfig = (
    data: Record<string, { key: string; value: string; description: string }>
  ) => {
    return Object.values(data).map(({ key, value, description }) => ({
      key,
      value: `${value}`,
      description,
    }));
  };
  
export default modifyMobileConfig;